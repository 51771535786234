function articleScroll() {
  let iscrollInitiated = false,
    iscrollMax = 1,
    initialArticleId = null,
    trackingArticleId = null,
    articlesToLoad = {},
    articlesLoaded = [],
    viewObserver = null,
    viewed_urls = [];

  $(function () {
    if (iscrollDisabled()) {
      return;
    }

    initViewObserver();
    initIscrollObserver();
  });

  /**
   * Check if iscroll is disabled.
   * @returns bool
   */
  const iscrollDisabled = () => {
    // Override disableIscroll if disableIscroll param is present.
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.get("disableIscroll") ||
      navigator.userAgent.indexOf("Chrome-Lighthouse") > -1
    ) {
      return true;
    }

    return (
      window.disableIscroll ||
      "undefined" === typeof ess_iscroll ||
      !$(".pubstack-post-header").length ||
      !$(".pubstack-infinite-scroll").length ||
      !$("#main article").length
    );
  };

  /**
   * Init iscroll observer
   */
  const initIscrollObserver = () => {
    const options = {
      rootMargin: `-300% 0px 300% 0px`, // 3 screen heights
      threshold: 0,
    };

    const handler = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          initIscrollOnce();
          loadNextArticle();
        }
      });
    };

    let iscrollObserver = new IntersectionObserver(handler, options);
    iscrollObserver.observe(document.querySelector(".site-footer"));
  };

  /**
   * Init url observer
   */
  const initViewObserver = () => {
    viewObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          const $currentArticle = $(entry.target);

          // Check if this article or any of its children has the 'na-sponsor' class
          const isSponsored = $currentArticle.find(".na-sponsor").length > 0;

          // Get the previous article relative to the current one
          const $prevArticle = $currentArticle.prevAll("article").first();

          if (isSponsored && $prevArticle.length) {
            if (entry.isIntersecting) {
              // Sponsored article is in view, add 'body' class to the previous article
              $prevArticle.addClass("body");
            } else {
              // Sponsored article is out of view, remove 'body' class from the previous article
              $prevArticle.removeClass("body");
            }
          }
          const post = $(entry.target).prev().data("url");
          if (entry.isIntersecting) {
            // Entering. Scrolling downwards.
            if (entry.boundingClientRect.top > 0) {
              onArticleView(entry.target);
              entry.target.ess_entered_view = true;
            }
          } else {
            // Initial page view. Or, scrolling upwards.
            if (entry.boundingClientRect.top > 0) {
              // Bypass the first call on observe start.
              if (typeof entry.target.ess_entered_view !== "undefined") {
                onArticleView($(entry.target).prevAll("article").get(0));
              }
            }
          }
        });
      },
      { threshold: 0 }
    );
  };

  /**
   * Init iscroll setup.
   */
  const initIscrollOnce = () => {
    if (iscrollInitiated) {
      return;
    }

    /* Initial article */
    initialArticleId = Number($(".pubstack-post-header").next().data("id"));
    trackingArticleId = initialArticleId; // Last article which is not loaded by injections
    articlesLoaded = [initialArticleId];

    getInjectionDetails();

    iscrollInitiated = true;
  };

  /**
   * Load next iscroll article.
   */
  const loadNextArticle = () => {
    // Check if another loading is in progress.
    if ($(".pubstack-infinite-scroll").hasClass("working")) {
      return false;
    }

    // Stop iscroll if max articles loaded.
    if (articlesLoaded.length > iscrollMax) {
      iscrollObserver.disconnect();
      return; // Exit function early to prevent further execution
    }

    const nextIscrollIndex = articlesLoaded.length;

    let data = {
      articlesLoaded,
    };

    if (Object.keys(articlesToLoad).includes(`${nextIscrollIndex}`)) {
      // check if article to inject is already loaded.
      const articleToLoad = articlesToLoad[nextIscrollIndex];

      if (
        !(
          articleToLoad.type === "random" &&
          articlesLoaded.includes(articleToLoad.nextPostId)
        )
      ) {
        data = { ...data, ...articleToLoad };
      }
    }

    $(".pubstack-infinite-scroll").addClass("working");
    fetch(
      pubstack.restUrl +
        "ess/v1/next-article/" +
        trackingArticleId +
        "?type=" +
        data["type"] +
        "&articlesLoaded=" +
        articlesLoaded +
        "&nextPostId=" +
        data["nextPostId"],
      {
        method: "GET",
        headers: {
          "Content-Type": "html/text",
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        $(".pubstack-infinite-scroll").replaceWith(html);

        const id = html.match(/<article id="(post-\d*)"/);
        const $article = id ? $(`#${id[1]}`) : false;
        if ($article && $article.length) {
          const articleId = $article[0].getAttribute("data-id");
          articlesLoaded.push(+articleId);

          if (!data.type) {
            trackingArticleId = articleId;
          }
          viewObserver.observe($article.get(0));
          runTriggers($article.prev());
        }
      })
      .catch((error) => {
        console.error("Could not load next article.");
        $(".pubstack-infinite-scroll").addClass("failed");
      });
  };

  /**
   * Get given count of random numbers between [start] and [end]
   */
  function getRandNumbers(start, end, count) {
    const arr = [...Array(end + 1).keys()].slice(start); // start=2, end=8 ---> E.g: [ 2,3,4,5,6,7,8 ]
    const length = end - start + 1;
    let rands = [];

    for (let i = 1; i <= count; i++) {
      var r = Math.floor(Math.random() * (length - i + 1));
      rands.push(arr[r]);
      arr[r] = arr[length - i];
    }

    return rands.sort(); // count=3 ---> E.g. [ 3,7,8 ]  [ 2,5,6 ] ...
  }

  /**
   * Get video + random articles lists to inject
   */
  function getInjectionDetails() {
    iscrollMax = ess_iscroll.ess_iscroll_max;

    // Let Google Optimize Test override the max variable.
    if (typeof window.maxIscroll !== "undefined") {
      iscrollMax = window.maxIscroll;
    }

    /* Random articles injection */
    if ("1" === ess_iscroll.ess_random_articles_enabled) {
      if ("global" === ess_iscroll.ess_injector_scope) {
        let ess_random_articles = ess_iscroll.ess_random_articles.filter(
          (x) => x !== initialArticleId
        );
        if (ess_random_articles.length) {
          // Get available slot count
          const slot_count = Math.min(
            ess_iscroll.ess_slot_count,
            iscrollMax,
            ess_random_articles.length
          );

          // Get random position vs. article
          const random_pos = getRandNumbers(1, iscrollMax - 1, slot_count);
          const random_indexes = getRandNumbers(
            0,
            ess_random_articles.length - 1,
            slot_count
          );

          // Add to injections
          random_indexes.forEach((index, i) => {
            articlesToLoad[random_pos[i]] = {
              type: "random",
              nextPostId: ess_random_articles[index],
            };
          });
        }
      } else if ("specific" === ess_iscroll.ess_injector_scope) {
        let toDedupe = [];
        ess_iscroll.ess_random_articles.forEach((slot) => {
          if (+slot.pos > iscrollMax) {
            return;
          }
          slot.articles = slot.articles.filter((x) => x !== initialArticleId);
          slot.articles = slot.articles.filter((x) => !toDedupe.includes(x));
          if (!slot.articles.length) {
            return;
          }
          const random_index = getRandNumbers(0, slot.articles.length - 1, 1);
          //ensure sponsored articles are prioritized
          if ("sponsored" === slot.type) {
            for (let i = 0; i < slot.articles.length; i++) {
              toDedupe.push(slot.articles[i]);
            }
            //choose one at random
            articlesToLoad[slot.pos] = {
              type: "random",
              nextPostId: slot.articles[random_index],
            };
          } else {
            //pick a random selected article for this slot
            toDedupe.push(slot.articles[random_index]);
            //dont override sponsored articles
            if (articlesToLoad[slot.pos] === undefined) {
              articlesToLoad[slot.pos] = {
                type: "random",
                nextPostId: slot.articles[random_index],
              };
            }
          }
        });
      }
    }

    /* Video injections */
    if (ess_iscroll.ess_iscroll_video) {
      let video_pos;
      let inj_count = iscrollMax;
      if ("end" === ess_iscroll.ess_iscroll_video_pos) {
        articlesToLoad[++inj_count] = { type: "video" };
        if (ess_iscroll.ess_iscroll_video2) {
          articlesToLoad[++inj_count] = { type: "video" };
        }
      } else {
        if (!isNaN(ess_iscroll.ess_iscroll_video1)) {
          video_pos = Math.min(ess_iscroll.ess_iscroll_video1, inj_count + 1); // Append video to the end if the pos is greater than max count
          // If any random article pos is the same as the video pos, insert video after the random article
          while (Object.keys(articlesToLoad).includes(`${video_pos}`)) {
            video_pos++;
          }
          articlesToLoad[video_pos] = { type: "video" };
        }
      }
    }
  }

  const trackPageView = ($articleHeader = null) => {
    if (!$articleHeader) {
      $articleHeader = $(".pubstack-post-header").first();
    }

    const post_data = $articleHeader.data("post-info");
    $(window).trigger("analytics:pageView", ["", post_data]);
  };

  const trackIscrollView = (iscrollIndex) => {
    $(window).trigger("analytics:event:iscrollView", [iscrollIndex]);
  };

  /**
   * On article view, update the url and trigger analytics events.
   */
  function onArticleView(currArticle) {
    const $currArticleHeader = $(currArticle).prev();
    const currUrl = $currArticleHeader.data("url");
    const currTitle = $currArticleHeader.data("title");

    if (window.location.href.split("#")[0] !== currUrl) {
      history.replaceState({}, currTitle, currUrl);
    }

    const postType = $currArticleHeader.data("post_type");
    if ("article" === postType) {
      $(window).trigger("gallery:updatesections");
    }

    // Remember the first article viewed at initial page load
    if (viewed_urls.length < 1) {
      viewed_urls.push($(currArticle).prevAll("article").prev().data("url"));
    }
    // Trigger tracking events if it's the first time viewing the next article.
    if (!viewed_urls.includes(currUrl)) {
      viewed_urls.push(currUrl);

      trackPageView($currArticleHeader);
      // The initial page view was already tracked with iScrollIndex = 0
      if (viewed_urls.length > 1) {
        trackIscrollView(viewed_urls.length - 1);
      }
    }
  }

  /**
   * Run triggers for 3rd parties after next article is loaded
   */
  function runTriggers(header) {
    $(window).trigger("ess_article_loaded", [header]);
    // $(window).trigger("jw:loadplayers");
    $(window).trigger("customCodeBlock:insert", [header]);
    $(window).trigger("injector:scroll_update", [header.next()]);
    $(window).trigger("omebed:renderInstagram", [header]);

    $(window).trigger("jwplayer:addVideo");
  }
}

export default articleScroll;
